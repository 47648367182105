import { useState, useEffect, useContext } from 'react';
import { useRouter } from 'next/router';
import Navbar from '../components/Navbar2';
import styles from '../styles/Birth.module.css';
import styles2 from '../styles/Dashboard.module.css';
import { UserContext } from '../lib/context';

export default function Start() {
  const router = useRouter();
  const { user, userData } = useContext(UserContext);

  return (
    <>
      <Navbar />
      <div className={`${styles['body-container2']} red`}>
        <div className={`${styles['page-container']} red`}>
          <div className={`${styles['title-container']} red`}>
            <div className={`${styles['page-title']} red`}>
              Cannot find the page you are looking for
            </div>
          </div>
          <div className={`${styles2['receipt-container2']} red`}>
            <div className={`${styles2['receipt-container2-row']} red`}>
              <button
                className={`${styles2['cart-item-btn-add']} red`}
                onClick={() => router.push('/dashboard')}
              >
                Back to Dashboard
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

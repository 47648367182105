import styles from '../styles/Landing.module.css';
import Link from 'next/link';
import Image from 'next/image';

export default function Navbar() {
  return (
    <nav className={`${styles['navbar-container3b']} red`}>
      <div className={`${styles['fauna-logo2']} red`}>
        <Link href="/consultations">
          <img src="/landing/gemma-yellow.png" />
        </Link>
      </div>
    </nav>
  );
}
